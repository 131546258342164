import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,_vm._l((_vm.items),function(item,i){return _c(VCol,{key:i,attrs:{"cols":"12"}},[_c(VCard,{attrs:{"color":item.color}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(item.desc))]),_c(VCardActions,[_c('div',{staticClass:"text-center"},[_c(VDialog,{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.cta)+" ")])]}}],null,true),model:{value:(_vm.dialog[i]),callback:function ($$v) {_vm.$set(_vm.dialog, i, $$v)},expression:"dialog[i]"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VCardText,[_c('p',{domProps:{"innerHTML":_vm._s(item.content.content)}})]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$set(_vm.dialog, i, false)}}},[_vm._v(" Fermer ")])],1)],1)],1)],1)])],1),_c('div',[_c(VImg,{attrs:{"src":item.src,"contain":"","max-width":"200","width":"100%"}})],1)])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }